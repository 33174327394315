<template>
<v-container>
  <v-list-item-group
    color="primary"
  >
    <v-list-item v-for="(item, i) in products" :key="i">
      <v-list-item-icon>
        <v-avatar
          style="margin-left: 0px;margin-right: 0px"
          :color="(item.color && item.images.length === 0 )? item.color : ''"
        >
          <v-img
            v-if="item.images.length > 0"
            :src="item.images.filter(im=>im.default)[0].path"
          />
        </v-avatar>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          <p>{{ item.name }}</p>
        </v-list-item-title>
        <v-card-text>
          <v-row>
            <v-col md="4" cols="12">
              <p><b>{{ $vuetify.lang.t('$vuetify.dashboard.sales') }}</b>:
                {{ item.cantTransactions + ' ' + item.um.name }} </p>
            </v-col>
            <v-col md="4" cols="12">
              <p><b>{{ $vuetify.lang.t('$vuetify.variants.total_price') }}</b>: {{ parseFloat(item.netPrice).toFixed(2) + ' ' + currency }} </p>
            </v-col>
            <v-col md="4" cols="12">
              <p><b>{{ $vuetify.lang.t('$vuetify.menu.discounts') }}</b>:
                {{
                  parseFloat(item.totalDiscount).toFixed(2) + ' ' + currency
                }} </p>
            </v-col>
            <v-col md="4" cols="12">
              <p><b>{{ $vuetify.lang.t('$vuetify.menu.tax_list') }}</b>:
                {{
                  parseFloat(item.totalTax).toFixed(2) + ' ' + currency
                }} </p>
            </v-col>
            <v-col md="4" cols="12">
              <p><b>{{ $vuetify.lang.t('$vuetify.menu.refund') }}</b>: {{
                  parseFloat(item.totalRefund).toFixed(2) + ' ' + currency
                }} </p>
            </v-col>
            <v-col md="4" cols="12">
              <p><b>{{ $vuetify.lang.t('$vuetify.report.grossPrice') }}</b>:
                {{ parseFloat(item.grossPrice).toFixed(2) + ' ' + currency }} </p>
            </v-col>
            <v-col md="4" cols="12">
              <p><b>{{ $vuetify.lang.t('$vuetify.variants.cost') }}</b>:
                {{ parseFloat(item.totalCost).toFixed(2) + ' ' + currency }} </p>
            </v-col>
            <v-col md="4" cols="12">
              <p><b>{{ $vuetify.lang.t('$vuetify.dashboard.revenue') }}</b>: {{
                  parseFloat(item.margin).toFixed(2) + ' ' + currency
                }} </p>
            </v-col>
            <v-col md="4" cols="12">
              <p><b>{{
                  $vuetify.lang.t('$vuetify.dashboard.mergeTable')
                }}</b>: {{ parseFloat(item.margin_percent).toFixed(2) + '%' }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</v-container>
</template>

<script>
export default {
  name: 'ProductsSummary',
  props: {
    products: {
      type: Array,
      default: function () {
        return []
      }
    },
    firstDate: {
      type: Date,
      default: function () {
        return new Date()
      }
    },
    secondDate: {
      type: Date,
      default: function () {
        return new Date()
      }
    },
    currency: {
      type: String,
      default: function () {
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>
